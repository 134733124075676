import Layout from './components/layout';

import { GatsbyImage } from 'gatsby-plugin-image';
import React, { useState } from 'react';
import Lightbox from 'react-18-image-lightbox';
import { Helmet } from 'react-helmet';

import 'react-18-image-lightbox/style.css';
import '../css/lightbox.css';
import { foodData } from '../graphql/food';
import { Image } from '../types';

const Food = () => {
  const data = foodData();
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Layout>
      <Helmet>
        <title>Food - Petra Flucka Photography</title>
        <meta property="og:title" content="Food - Petra Flucka Photography" />
        <meta property="og:image" content={data.thumbnails[0].src} />
        <meta property="og:url" content="https://petraflucka.com/food/" />
        <meta
          property="og:description"
          content="Need product, recipe, or meal shots? I capture the colors, textures, and traditions of your food in rich images that add depth and flavor to the screen."
        />
      </Helmet>
      {isOpen && (
        <Lightbox
          animationDisabled={true}
          enableZoom={false}
          mainSrc={data.images[photoIndex].src}
          nextSrc={data.images[(photoIndex + 1) % data.images.length].src}
          prevSrc={data.images[(photoIndex + data.images.length - 1) % data.images.length].src}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() => setPhotoIndex((photoIndex + data.images.length - 1) % data.images.length)}
          onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % data.images.length)}
        />
      )}

      <div className="grid grid-cols-1 md:grid-cols-5 md:gap-1 cursor-pointer">
        {data.thumbnails.map((image: Image, index) => {
          return (
            <div
              key={image.key}
              className={image.gatsbyImage.width > image.gatsbyImage.height ? 'col-span-2' : ''}
              onClick={() => {
                setPhotoIndex(index);
                setIsOpen(true);
              }}
            >
              <div className="pb-1 md:pb-0">
                <GatsbyImage
                  key={image.key}
                  backgroundColor="#F4F4F4"
                  image={{
                    ...image.gatsbyImage,
                    layout: 'fullWidth',
                    width: image.gatsbyImage.width > image.gatsbyImage.height ? 500 : 248,
                  }}
                  alt=""
                />
              </div>
            </div>
          );
        })}
      </div>
    </Layout>
  );
};

export default Food;
