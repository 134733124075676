import { graphql, useStaticQuery } from 'gatsby';
import { getSrc, IGatsbyImageData } from 'gatsby-plugin-image';

import { Image } from '../types';

export const foodData = (): { thumbnails: Image[]; images: Image[] } => {
  const result = useStaticQuery(graphql`
    query {
      thumbnails: allFile(
        filter: { sourceInstanceName: { eq: "food" } }
        sort: { fields: [name], order: [ASC, DESC] }
      ) {
        edges {
          node {
            id
            name
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, height: 400)
            }
          }
        }
      }
      images: allFile(filter: { sourceInstanceName: { eq: "food" } }, sort: { fields: [name], order: [ASC, DESC] }) {
        edges {
          node {
            id
            name
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, height: 2000)
            }
          }
        }
      }
    }
  `);
  const images = result.images.edges.map(
    (edge: { node: { id: string; name: string; childImageSharp: { gatsbyImageData: IGatsbyImageData } } }) => ({
      key: edge.node.id,
      gatsbyImage: edge.node.childImageSharp.gatsbyImageData,
      src: getSrc(edge.node.childImageSharp.gatsbyImageData),
      originalName: edge.node.name,
    }),
  );
  const thumbnails = result.thumbnails.edges.map(
    (edge: { node: { id: string; name: string; childImageSharp: { gatsbyImageData: IGatsbyImageData } } }) => ({
      key: edge.node.id,
      gatsbyImage: edge.node.childImageSharp.gatsbyImageData,
      src: getSrc(edge.node.childImageSharp.gatsbyImageData),
      originalName: edge.node.name,
    }),
  );
  return { images, thumbnails };
};
